<template>
    <div class="sdjz2Footer">
        <div class="footer-top">
            <div class="left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/footer-logo2.png" alt="">
            </div>
            <div class="right">
                <!-- <span>培训监管</span><span>业务监管（政府）</span><span>上门证审批</span> -->
                <p>地址：北京市西城区北三环中路燕莎盛世大厦5层</p>
                <p>电话：400-8555-686</p>
            </div>
        </div>
        <!-- <div class="bottom">
            <span>版权所有：伟东国信</span>    <span>技术支持：伟东云教育</span> 
        </div> -->
        <saas-nsr-footer :textColor="'#363636'" :bgColor="'#EAEAEA'"/>
    </div>
</template>
<script>
export default {
    name:'zhaohuFooter',
    data(){
        return{

        }
    }
}
</script>
<style lang="stylus" scoped>
.sdjz2Footer{
    width 100%
    height auto
    background #EEEEEE
    .footer-top{
        width 1200px
        height 132px
        margin 0 auto
        display flex
        justify-content space-between
        align-items center
        .left{
            width 350px
            img{
                width 350px
            }    
        }
        .right{
            p{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4D4D4D;
                line-height: 22px;
            }
            span{
                margin-left 48px
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4D4D4D;
                line-height: 22px;
            }
            .line{
                margin-left 30px
                margin-right 30px
            }
        }

    }
    .bottom{
        width 100%
        height 52px
        text-align center
        line-height 52px
        background rgba(219, 219, 219, .5)
        
        span{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4D4D4D;
            &:nth-child(2){
                margin-left 20px    
            }
        }
    }
}
</style>